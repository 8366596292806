@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg: #ffffff;
  --primary: #005f83;
}

* {
  color: black;
}

::-webkit-scrollbar {
  display: none;
}

body {
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 0 8px;
}

#root {
  background: #ffffff;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
}

input:is(:active, :focus, :focus-visible, :focus-within),
select:is(:active, :focus, :focus-visible, :focus-within) {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
